"use client"

import { Card } from "@/components/ui/card"

// Define the FAQ items interface
interface FAQItem {
  question: string
  answer: string
}

// Combined and deduplicated FAQ items
export const FAQ_ITEMS: FAQItem[] = [
  {
    question: "What is my IP address?",
    answer: "Your IP address is shown above. It's your unique identifier online, letting you connect to websites and services. We automatically detect and show both IPv4 and IPv6 addresses if available."
  },
  {
    question: "Is my IP address private?",
    answer: "Your IP address is visible to websites you visit. To protect your privacy, consider using a VPN service which masks your real IP address."
  },
  {
    question: "What information can be found from my IP?",
    answer: "An IP address can reveal your approximate location (city and country), your Internet Service Provider (ISP), connection type, and whether you're using a VPN or proxy. It cannot show your exact address or personal information."
  },
  {
    question: "How can I hide my IP address?",
    answer: "You can hide your IP address by using a VPN service, proxy server, or the Tor network. A VPN is generally the most secure and reliable method, especially when using public WiFi or accessing sensitive information online."
  },
  {
    question: "Why is IP geolocation not accurate?",
    answer: "IP geolocation provides approximate locations based on IP address databases. The accuracy can vary depending on your ISP and how they assign IP addresses across their network."
  },
  {
    question: "Is it safe to check my IP address?",
    answer: "Yes, checking your IP address on our site is completely safe. We don't store your IP information or share it with third parties. We only display the information that's already visible to websites you visit."
  }
]

export function FAQSection() {
  return (
    <section className="mx-auto max-w-3xl py-12">
      <h2 className="text-2xl font-bold mb-8 text-center">
        Frequently Asked Questions
      </h2>
      <div className="space-y-6">
        {FAQ_ITEMS.map((item, index) => (
          <Card key={index} className="p-6">
            <h3 className="font-semibold mb-2">{item.question}</h3>
            <p className="text-muted-foreground">{item.answer}</p>
          </Card>
        ))}
      </div>
      <SchemaMarkup />
    </section>
  )
}

// Include the schema markup in the same file
function SchemaMarkup() {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": FAQ_ITEMS.map(item => ({
            "@type": "Question",
            "name": item.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": item.answer
            }
          }))
        })
      }}
    />
  )
}