"use client"

import { useState } from "react"
import { Search, Loader2 } from "lucide-react"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { useRouter } from "next/navigation"
import { cn } from "@/lib/utils"

export function IPLookup() {
  const [searchIP, setSearchIP] = useState("")
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const isValidIP = (ip: string): boolean => {
    // IPv4 regex
    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/
    
    // IPv6 regex - handles full and compressed formats
    const ipv6Regex = /^(?:(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,7}:|(?:[0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|(?:[0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|(?:[0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|(?:[0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:(?:(:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/

    return ipv4Regex.test(ip) || ipv6Regex.test(ip)
  }

  const handleSearch = async () => {
    setIsLoading(true)
    const trimmedIP = searchIP.trim()
    
    if (!trimmedIP) {
      setError("Please enter an IP address")
      setIsLoading(false)
      return
    }

    if (!isValidIP(trimmedIP)) {
      setError("Please enter a valid IPv4 or IPv6 address")
      setIsLoading(false)
      return
    }

    setError("")
    try {
      await router.push(`/ip/${encodeURIComponent(trimmedIP)}`)
    } finally {
      setTimeout(() => setIsLoading(false), 300)
    }
  }

  return (
    <div className="w-full max-w-md transform opacity-0 transition-all duration-500 animate-fade-in">
      <div className="relative">
        <Input
          type="text"
          placeholder="Enter IPv4 or IPv6 address..."
          value={searchIP}
          onChange={(e) => {
            setSearchIP(e.target.value)
            setError("")
          }}
          className={`pr-24 transition-all duration-300 ${error ? 'border-destructive shadow-[0_0_0_1px_rgba(239,68,68,0.2)]' : ''}`}
          onKeyDown={(e) => e.key === 'Enter' && !isLoading && handleSearch()}
          disabled={isLoading}
        />
        <Button
          size="sm"
          variant="ghost"
          className="absolute right-1 top-1 h-7 transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-800"
          onClick={handleSearch}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="relative">
              <div className="absolute inset-0 animate-ping rounded-full bg-primary/30" />
              <Loader2 className="h-5 w-5 animate-spin relative text-primary" />
            </div>
          ) : (
            <>
              <Search className="h-5 w-5 mr-2" />
              <span>Lookup</span>
            </>
          )}
        </Button>
      </div>
      {error && (
        <p className="mt-2 text-sm text-destructive animate-slide-down">
          {error}
        </p>
      )}
    </div>
  )
}