"use client"

import { useEffect, useState } from "react"
import { Shield, Zap, Eye } from "lucide-react"
import { Card } from "@/components/ui/card"
import { Progress } from "@/components/ui/progress"
import { cn } from "@/lib/utils"
import type { IPInfo } from "@/types/ip"

interface VPNDetectorProps {
  ipInfo: IPInfo
}

export function VPNDetector({ ipInfo }: VPNDetectorProps) {
  const [progress, setProgress] = useState(0)
  const [isAnimating, setIsAnimating] = useState(true)
  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(timer)
          setIsAnimating(false)
          return 100
        }
        return prev + 2
      })
    }, 40)
    return () => clearInterval(timer)
  }, [])

  const getSecurityStatus = () => {
    if (ipInfo?.is_proxy) return { 
      level: "Protected", 
      color: "text-green-500",
      icon: <Shield className="h-5 w-5 text-green-500" />,
      message: "Your connection is secure"
    }
    return { 
      level: "At Risk", 
      color: "text-red-400",
      icon: <Eye className="h-5 w-5 text-red-400 animate-pulse" />,
      message: "Your location is visible to websites"
    }
  }

  const status = getSecurityStatus()

  return (
    <Card className="relative overflow-hidden border-none bg-background/95 p-6 
                    shadow-lg backdrop-blur-xl dark:bg-gray-950/90
                    sm:mx-auto sm:max-w-full
                    md:max-w-md">
      <div className="absolute inset-0 bg-gradient-to-tr from-background/5 via-primary/5 to-background/5 dark:from-gray-950/20 dark:via-primary/10 dark:to-gray-950/20" />
      
      <div className="relative space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="rounded-full bg-primary/10 p-2.5">
              <Shield className="h-5 w-5 text-primary" />
            </div>
            <div>
              <span className="block text-sm font-medium">Connection Status</span>
              <span className="text-xs text-muted-foreground">Privacy check</span>
            </div>
          </div>
          {isAnimating ? (
            <Zap className="h-5 w-5 animate-pulse text-primary" />
          ) : (
            status.icon
          )}
        </div>

        {isAnimating ? (
          <div className="space-y-3">
            <div className="flex justify-between text-xs">
              <span className="text-muted-foreground">Checking connection...</span>
              <span className="text-primary">{progress}%</span>
            </div>
            <Progress value={progress} className="h-1" />
          </div>
        ) : (
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm text-muted-foreground">Status</span>
                <span className={cn("text-sm font-medium", status.color)}>
                  {status.level}
                </span>
              </div>
              <p className="text-sm">{status.message}</p>
            </div>

            {!ipInfo?.is_proxy && (
              <button 
                onClick={() => window.open('https://nordvpn.com/', '_blank')}
                className="group w-full rounded-lg bg-primary px-4 py-3 text-sm font-medium 
                           text-primary-foreground transition-all hover:bg-primary/90
                           hover:shadow-lg hover:shadow-primary/20 active:scale-[0.99]"
              >
                Protect My Privacy Now
                <span className="ml-1 text-xs opacity-70">• Secure Connection</span>
              </button>
            )}
          </div>
        )}
      </div>
    </Card>
  )
}